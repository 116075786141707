// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import 'material-icons.css';
@import 'material-fonts.css';
@import 'style-vars-global.css';

$dmx-cloud-wetter-terminal-primary: mat.define-palette(mat.$blue-palette);
$dmx-cloud-wetter-terminal-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$dmx-cloud-wetter-terminal-warn: mat.define-palette(mat.$red-palette);
$dmx-cloud-wetter-terminal-success: mat.define-palette(mat.$green-palette);
$dmx-cloud-wetter-terminal-theme: mat.define-light-theme((
  color: (
    primary: $dmx-cloud-wetter-terminal-primary,
    accent: $dmx-cloud-wetter-terminal-accent,
    warn: $dmx-cloud-wetter-terminal-warn,
    success: $dmx-cloud-wetter-terminal-success
  )
));

@include mat.all-component-themes($dmx-cloud-wetter-terminal-theme);
@include mat.all-component-typographies();
@include mat.core();


/* You can add global styles to this file, and also import other style files */

html, body {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', "Serif", "SansSerif", serif;
  font-size: var(--medium-font);
}


/*Globales überschreiben aller mat-buttons buttons*/
.mat-mdc-raised-button,
.mat-mdc-flat-button,
.mat-mdc-stroked-button,
.mat-mdc-outlined-button,
.mat-mdc-fab,
.mat-mdc-outlined-button {
  border-radius: var(--default-border-radius) !important;
  height: var(--default-button-height) !important;
  font-size: var(--medium-font) !important;
}

.spacer {
  flex: auto;
}

/*Beginn Table Definition*/
.mat-mdc-table thead, .mat-mdc-header-row, .mat-mdc-table .mat-mdc-header-cell {
  font-weight: 600;
  color: rgb(84, 84, 84);
}

tr.mat-row:hover {
  background-color: var(--default-hover-background-color);
}

/*End Table Definition*/

/**
Beginn Tab-Header Styling
 */
.mat-mdc-tab-header {
  position: fixed !important;
  z-index: 999;
  background: white;
  width: 100%;
  margin-top: -1px;
}

.mat-mdc-tab-body {
  margin-top: 45px !important;
}

//Mat-Chip-Listbox Style Override
.mat-mdc-standard-chip {
  border: 1px solid rgba(140, 140, 140, 0.2);
}

.mat-mdc-standard-chip.mdc-evolution-chip--selectable:not(.mdc-evolution-chip--with-primary-icon) {
  background: rgba(255, 255, 255, 0.9);
}

.mat-mdc-chip-action-label:hover {
  cursor: pointer;
}

.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  background: var(--default-background-color);
  font-weight: 500;
  color: white;
}

.hint-text {
  color: var(--default-accent-color);
  font-weight: bold;
}


// Nach dem Update mussten die Styles vom datetime-picker neu überschrieben werden
// TODO: Wenn es eine aktuelle Version des pickers für Angular 17 gibt, dann die installieren
// TODO: Das noch einmal versuchen in die Styles der Komponente zu schreiben

/*Datepicker styling*/
.timepicker-container {
  margin-right: var(--default-margin);
  margin-left: 10px;
  background-color: transparent;
}

.timepicker-input {
  text-align: center;
}

.mat-datepicker-content {
  background: white !important;
  border-radius: 18px !important;
}

.mat-calendar-body-cell-content.mat-focus-indicator {
  border-color: transparent;
}

.mat-calendar-body-cell-content.mat-focus-indicator:hover {
  background: rgba(33, 150, 243, 0.5);
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  background: #2196f3;
  color: white;
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-today {
  border-color: rgba(128, 128, 128, 0.7);
}

.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper {
  --mat-mdc-form-field-label-offset-x: 1px !important;
}

//Ende datetime-picker Style Override
