/*Standard Variablen, die global verwendet werden*/
:root {
  --large-font: 20px;
  --larger-font: 16px;
  --more-medium-font: 15px;
  --medium-font: 14px;
  --small-font: 13px;

  --default-border-radius: 30px;
  --default-button-width: 100px;
  --default-button-height: 42px;

  --default-margin: 15px;
  --small-margin: 5px;
  --larger-margin: 10px;
  --large-margin: 20px;

  --default-hover-background-color: rgba(211, 211, 211, 0.5);
  --default-caption-color: rgba(37, 35, 35);

  --default-background-color: #3b82f6;
  --default-background-color-gradient: linear-gradient(
    90deg,
    rgba(59, 130, 246, 1) 0%,
    rgba(91, 169, 252, 1) 100%
  );
  --default-accent-color: #ffab40;
  --light-background-color: rgba(59, 130, 246, 0.5);
  --secondary-background-color: rgb(91, 169, 252);
  --default-border-color: rgba(0, 0, 0, 0.25);
  --error-color: #c71717;
}


